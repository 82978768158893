/*
 * Bones Scripts File
 * Author: Eddie Machado
 *
*/


/*
 * Get Viewport Dimensions
 * returns object with viewport dimensions to match css in width and height properties
 * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
*/
function updateViewportDimensions() {
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return { width:x,height:y };
}
// setting the viewport width
var viewport = updateViewportDimensions();


/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
*/
var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
		if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 100;

/* https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices */
function watchForHover(container) {
    var hasHoverClass = false,
        lastTouchTime = 0;
    function enableHover() {
        // filter emulated events coming from touch events
        if (new Date() - lastTouchTime < 500) return;
        if (hasHoverClass) return;
        container.className += ' has-hover';
        hasHoverClass = true;
    }
    function disableHover() {
        if (!hasHoverClass) return;
        container.className = container.className.replace(' has-hover', ' has-no-hover');
        hasHoverClass = false;
    }
    function updateLastTouchTime() {
        lastTouchTime = new Date();
    }
    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
    enableHover();
}
watchForHover(document.querySelector('body'));


const toggleContainers = (a, b, c, d) => {
	let zli = document.createElement('LI')
		zli.setAttribute('class', 'close-li')
		
	let	zq = document.createElement('BUTTON')
		zq.setAttribute('type', 'button')
		zq.setAttribute('class', 'close')
		zq.innerHTML = 'close'
	b.classList.remove(c)
	let orig = document.querySelector('.orig')
	
	
	a.addEventListener("click", ()=>{
			b.classList.add(c)
			zli.appendChild(zq)
			b.appendChild(zli)
			document.body.classList.add(d)
			console.log('should focus')
			orig.focus()
			console.log(orig)
	})
	zq.addEventListener("click", (e)=>{
		if(b.classList.contains(c) ) {
			b.classList.remove(c)
			document.body.classList.remove(d)
		}
	})
	zli.addEventListener("click", (e)=>{
		if(b.classList.contains(c) ) {
			b.classList.remove(c)
			document.body.classList.remove(d)
		}
	})
}


const menuOperations = (e, d, j) => {
	e.forEach( (mo)=>{
		mo.addEventListener('click', (f) => {
			f.target.previousElementSibling.classList.toggle(d) 
			j.classList.toggle('header-open')
			document.body.classList.toggle('fixed')
		})
	})
}
menuOperations(document.querySelectorAll('.header-nav-button'), 'open-nav', document.querySelector('.header'))

	window.addEventListener("DOMContentLoaded", () => {
	toggleContainers(document.querySelector('.search-in-menu > a'), document.querySelector('.search-in-menu .sub-menu'), 'expand-search', 'fixed' )
	let vselpast = Array.from(document.querySelectorAll('.vsel-list-long .vsel-shortcode-past-events .vsel-content'))
	let loadMore = document.getElementById('loadMore')
	let w  = []
	
	const vselLoading = (a)=>{
		
		//a.forEach((c)=>{ c.classList.add('waiting') })
		let n = 2;
		let r = a.slice(0, n);
		
		r.forEach((f)=>{ f.classList.remove('waiting') })
		a.forEach((c)=>{ if(c.classList.contains('waiting')) { w.push(c) } })
		
		loadMore.addEventListener('click', (e)=>{
			let p = w.slice(0, n)
			p.forEach((f)=>{f.classList.remove('waiting')})
			w.splice(0, n)
			//a.forEach((c)=>{
			//	if(c.classList.contains('waiting')) { 
					//w.push(c) 
					
			//	}
			//})
			//console.log(w.length)
			if(w.length < 1) {
				e.target.setAttribute('disabled', 'disabled')
				e.target.innerHTML = 'Нямо повече изминали събития'
			}
		})
		
	}
	if(loadMore) vselLoading(vselpast)
	//accordion(document.querySelectorAll('.accordion-title'))
});
